$red: #da2222;
$error_red: #D42729;
$white: #ffffff;
$white-two: #f7f7f7;
$white-three: #e2f2fd;
$deep-blue: #003057;
$light-blue: #0077bc;
$lighter-blue: #E6F6FF;
$light-gray: #b3b3b3;
$stutter-gray: #EAEAEA;
$lighter-gray: #F7F7F7;
$medium-gray: #d9d9d9;
$darkish-gray: #a7a7a7;
$dark-gray: #808080;
$dark-2-gray: #7c7c7c;
$darker-gray: #656565;
$placeholder: #808285;
$rich-black: #404040;
$light-black: #505050;
$green: #3c853e;
$orange: #FAA41A;
$teal: #009e8c;
$spotlight: #FAA41A;
$heatmap0: #dbf0fa;
$heatmap1: #91cff2;
$heatmap2: #1C87C5;
$heatmap3: #ece35a;
$heatmap4: #faae1a;
$heatmap5: #c62525;
$oldheatmap0: #81d6cc;
$oldheatmap1: #66ccc0;
$oldheatmap2: #4ec2b4;
$oldheatmap3: #23ad9d;
$oldheatmap4: #10a392;
$oldheatmap5: #009987;

$zindex-filter-panel: 8;
$zindex-toast: 7;
$zindex-collapsible-panel-toggle-strip: 6;
$zindex-selection-panel: 5;
$zindex-details-panel: 10;
$zindex-nav-bar: 15;

@mixin calc($property,
    $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -o-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}


//dynamic images function
@mixin background-3x($path, $ext: "png", $w: auto, $h: auto, $pos: left top, $repeat: no-repeat) {

    $at1x_path: "#{$path}.#{$ext}";
    $at2x_path: "#{$path}@2x.#{$ext}";
    $at3x_path: "#{$path}@3x.#{$ext}";

    background-image: url("#{$at1x_path}");
    background-size: $w $h;
    background-position: $pos;
    background-repeat: $repeat;

    @media (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 2dppx) {
        background-image: url("#{$at2x_path}");
    }

    @media (-webkit-min-device-pixel-ratio: 3),
    (min-resolution: 3dppx) {
        background-image: url("#{$at3x_path}");
    }
}

@-webkit-keyframes LoadingFade {
    0% {
        background-position: 0% 0%
    }

    50% {
        background-position: 100% 0%
    }

    100% {
        background-position: 0% 0%
    }
}

@-moz-keyframes LoadingFade {
    0% {
        background-position: 0% 0%
    }

    50% {
        background-position: 100% 0%
    }

    100% {
        background-position: 0% 0%
    }
}

@keyframes LoadingFade {
    0% {
        background-position: 0% 0%
    }

    50% {
        background-position: 100% 0%
    }

    100% {
        background-position: 0% 0%
    }
}
