/* You can add global styles to this file, and also import other style files */
@import '@kite/vanilla/scss/global';
@import "vars";

body {
    font-family: "Open Sans";
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    line-height: 1.5;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;

    &.fullscreen-overlay-open {
        overflow: hidden;
    }
}

.redText {
    color: $red;
}

input {
    font-family: "Open Sans";
}

pre {
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
}

.password-policy {
    font-size: 14px;
    line-height: 20px;
    color: $rich-black;
    font-weight: 300;

    &.first-item {
        margin-top: 5px;
    }

    &.last-item {
        margin-bottom: 20px;
    }

    .policy-icon {
        width: 12px;
        height: 12px;
        display: inline-block;
        margin-left: 10px;
    }

    .policy-title {
        display: inline-block;
        width: 202px;
    }

    &.policy-passed {
        .policy-title {
            color: $green;
        }

        .policy-icon {
            @include background-3x("/assets/icons//ic-success",
                "png",
                12px,
                12px,
                left top,
                no-repeat);
        }
    }

    &.policy-failed {
        .policy-title {
            color: $red;
        }

        .policy-icon {
            @include background-3x("/assets/icons//ic-error",
                "png",
                12px,
                12px,
                left top,
                no-repeat);
        }
    }
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.box-shadow {
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.box-shadow-bottom {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.clearfix {
    display: block;
    content: "";
    clear: both;
}

[type="search"] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $placeholder;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: $placeholder;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: $placeholder;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: $placeholder;
}

::-webkit-scrollbar {
    width: 9px;
    height: 9px;
}

::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background: #888
}

.sort-explainer {
    font-size: 12px;
    color: #404040;
    margin-top: 0px;
    margin-bottom: 20px;
}

.sort-toggle {
    height: 24px;
}

:root {
    --kite-switch-label-margin-right: 12px;
}
